/* Products Page Styles */
.products-page {
  min-height: 100vh;
  background: var(--background-light);
}

/* Products Hero Section */
.products-hero {
  background: var(--gradient-bottom-right);
  color: white;
  text-align: center;
  padding: var(--spacing-xl) var(--spacing-lg);
  position: relative;
  overflow: hidden;
}

.products-hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.products-hero h1,
.products-hero p,
.products-hero .hero-cta {
  position: relative;
  z-index: 1;
}

.products-hero h1 {
  font-size: var(--font-size-3xl);
  margin-bottom: var(--spacing-sm);
  animation: slideInLeft var(--transition-normal) ease-out;
}

.products-hero p {
  font-size: var(--font-size-lg);
  opacity: 0.9;
  max-width: 800px;
  margin: 0 auto var(--spacing-md);
  animation: fadeInUp var(--transition-normal) ease-out 0.2s forwards;
}

.hero-subtitle {
  font-size: var(--font-size-base);
  animation: fadeIn var(--transition-normal) ease-out 0.4s forwards;
  opacity: 0;
}

.hero-cta {
  background: var(--accent-color);
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  transition: all var(--transition-fast);
  animation: bounceIn var(--transition-normal) ease-out 0.6s forwards;
  opacity: 0;
}

.hero-cta:hover {
  background: var(--primary-color);
  transform: scale(1.05);
}

/* Product Categories */
.product-categories {
  padding: var(--spacing-xl) 0;
  background: var(--background-dark);
}

.product-category {
  margin-bottom: var(--spacing-xl);
}

.product-category h2 {
  color: var(--text-primary);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-2xl);
  text-align: center;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--spacing-lg);
}

.product-card {
  padding: var(--spacing-lg);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all var(--transition-fast);
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  background: var(--gradient-top-right);
  color: white;
}

.product-icon {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-md);
}

.product-card h3 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
}

.product-card:hover h3 {
  color: white;
}

.product-card p {
  margin-bottom: var(--spacing-md);
}

.product-card ul {
  list-style: none;
  padding-left: 0;
}

.product-card li {
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-xs);
  position: relative;
  padding-left: var(--spacing-sm);
}

.product-card li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--accent-color);
}

/* Product Features */
.product-features {
  padding: var(--spacing-xl) 0;
  background: var(--fawn);
}

.product-features h2 {
  text-align: center;
  color: var(--russian-violet);
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-2xl);
}

.features-subtitle {
  text-align: center;
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xl);
  font-size: var(--font-size-base);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-lg);
}

.feature {
  text-align: center;
  padding: var(--spacing-md);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  transition: all var(--transition-fast);
}

.feature:hover {
  transform: scale(1.05);
  background: var(--citrine);
}

.feature-icon {
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-sm);
}

.feature h3 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
}

/* Testimonials */
.testimonials {
  padding: var(--spacing-xl) 0;
  background: var(--background-dark);
}

.testimonials h2 {
  text-align: center;
  color: var(--text-primary);
  margin-bottom: var(--spacing-xl);
  font-size: var(--font-size-2xl);
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
}

.testimonial-card {
  padding: var(--spacing-lg);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all var(--transition-fast);
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.testimonial-card p {
  margin-bottom: var(--spacing-sm);
}

.author {
  font-style: italic;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

/* Animations */
@keyframes slideInLeft {
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInUp {
  from { transform: translateY(30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes bounceIn {
  0% { transform: scale(0.8); opacity: 0; }
  60% { transform: scale(1.05); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .products-hero,
  .product-categories,
  .product-features,
  .testimonials {
    padding: var(--spacing-lg) 0;
  }

  .products-hero h1 { font-size: var(--font-size-2xl); }
  .products-hero p { font-size: var(--font-size-base); }
  .product-category h2,
  .product-features h2,
  .testimonials h2 { font-size: var(--font-size-xl); }

  .products-grid,
  .features-grid,
  .testimonials-grid {
    grid-template-columns: 1fr;
  }

  .product-card,
  .feature,
  .testimonial-card {
    padding: var(--spacing-md);
  }
}