/* Services Page Styles */
.services-page {
  min-height: 100vh;
  background: var(--background-light);
  overflow-x: hidden;
}

/* Services Hero Section */
.services-hero {
  background: var(--gradient-bottom-right);
  color: white;
  text-align: center;
  padding: var(--spacing-xl) var(--spacing-lg);
  position: relative;
  overflow: hidden;
}

.services-hero::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.15) 0%, transparent 70%);
  animation: rotateGlow 15s linear infinite;
  z-index: 0;
}

.services-hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.services-hero h1,
.services-hero p,
.services-hero .hero-cta {
  position: relative;
  z-index: 1;
}

.services-hero h1 {
  font-size: var(--font-size-3xl);
  margin-bottom: var(--spacing-sm);
  animation: slideInLeft var(--transition-normal) ease-out;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
}

.services-hero p {
  font-size: var(--font-size-lg);
  opacity: 0.9;
  max-width: 800px;
  margin: 0 auto var(--spacing-md);
  animation: fadeInUp var(--transition-normal) ease-out 0.2s forwards;
}

.hero-subtitle {
  font-size: var(--font-size-base);
  animation: fadeIn var(--transition-normal) ease-out 0.4s forwards;
  opacity: 0;
}

.hero-cta {
  background: var(--accent-color);
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  transition: transform var(--transition-fast), background var(--transition-fast);
  animation: bounceIn var(--transition-normal) ease-out 0.6s forwards;
  opacity: 0;
  will-change: transform;
}

.hero-cta:hover {
  background: var(--primary-color);
  transform: scale(1.05);
}

/* Services Grid */
.services-grid {
  padding: var(--spacing-xl) 0;
  background: var(--background-dark);
}

.services-grid h2 {
  text-align: center;
  color: var(--text-primary);
  margin-bottom: var(--spacing-xl);
  font-size: var(--font-size-2xl);
}

.services-grid .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
}

.service-card {
  padding: var(--spacing-lg);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all var(--transition-fast);
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  background: var(--gradient-top-right);
  color: white;
}

.service-icon {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-md);
}

.service-card h3 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
}

.service-card:hover h3 {
  color: white;
}

.service-card p {
  margin-bottom: var(--spacing-md);
}

.service-card ul {
  list-style: none;
  padding-left: 0;
}

.service-card li {
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-xs);
  position: relative;
  padding-left: var(--spacing-sm);
}

.service-card li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: var(--accent-color);
}

/* Process Section */
.process {
  padding: var(--spacing-xl) 0;
  background: var(--fawn);
}

.process h2 {
  text-align: center;
  color: var(--russian-violet);
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-2xl);
}

.process-subtitle {
  text-align: center;
  color: var(--text-secondary);
  margin-bottom: var(--spacing-xl);
  font-size: var(--font-size-base);
}

.process-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: var(--spacing-lg);
}

.step {
  text-align: center;
  padding: var(--spacing-md);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  transition: all var(--transition-fast);
}

.step:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.step-number {
  width: 40px;
  height: 40px;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto var(--spacing-sm);
  font-size: var(--font-size-lg);
}

.step h3 {
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

/* Industries Section */
.industries {
  padding: var(--spacing-xl) 0;
  background: var(--background-dark);
}

.industries h2 {
  text-align: center;
  color: var(--text-primary);
  margin-bottom: var(--spacing-xl);
  font-size: var(--font-size-2xl);
}

.industries-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacing-lg);
}

.industry-card {
  padding: var(--spacing-lg);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  text-align: center;
  transition: all var(--transition-fast);
}

.industry-card:hover {
  background: var(--citrine);
  color: var(--text-primary);
  transform: translateY(-5px);
}

.industry-card h3 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
}

/* Animations */
@keyframes slideInLeft {
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInUp {
  from { transform: translateY(30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes bounceIn {
  0% { transform: scale(0.8); opacity: 0; }
  60% { transform: scale(1.05); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes pulse {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-hero,
  .services-grid,
  .process,
  .industries {
    padding: var(--spacing-lg) 0;
  }

  .services-hero h1 { font-size: var(--font-size-2xl); }
  .services-hero p { font-size: var(--font-size-base); }
  .services-grid h2,
  .process h2,
  .industries h2 { font-size: var(--font-size-xl); }

  .services-grid .grid,
  .process-steps,
  .industries-grid {
    grid-template-columns: 1fr;
  }

  .service-card,
  .step,
  .industry-card {
    padding: var(--spacing-md);
  }
}