/* Global CSS Variables */
:root {
  /* Colors */
  --primary-color: #2563eb;
  --secondary-color: #1e40af;
  --accent-color: #3b82f6;
  --text-primary: #1f2937;
  --text-secondary: #4b5563;
  --background-light: #ffffff;
  --background-dark: #f3f4f6;
  --border-color: #e5e7eb;
  
  /* Additional Colors */
  --fawn: #ffb86f;
  --citrine: #e0ca3c;
  --burnt-orange: #ba5c12;
  --russian-violet: #3e2f5b;

  /* Typography */
  --font-primary: system-ui, -apple-system, sans-serif;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;

  /* Spacing */
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 3rem;

  /* Border Radius */
  --border-radius-sm: 0.25rem;
  --border-radius-md: 0.5rem;
  --border-radius-lg: 1rem;

  /* Transitions */
  --transition-fast: 150ms ease-in-out;
  --transition-normal: 250ms ease-in-out;
  
  /* Gradients */
  --gradient-top: linear-gradient(0deg, var(--fawn), var(--citrine), var(--burnt-orange), var(--russian-violet));
  --gradient-right: linear-gradient(90deg, var(--fawn), var(--citrine), var(--burnt-orange), var(--russian-violet));
  --gradient-bottom: linear-gradient(180deg, var(--fawn), var(--citrine), var(--burnt-orange), var(--russian-violet));
  --gradient-left: linear-gradient(270deg, var(--fawn), var(--citrine), var(--burnt-orange), var(--russian-violet));
  --gradient-top-right: linear-gradient(45deg, var(--fawn), var(--citrine), var(--burnt-orange), var(--russian-violet));
  --gradient-bottom-right: linear-gradient(135deg, var(--fawn), var(--citrine), var(--burnt-orange), var(--russian-violet));
  --gradient-top-left: linear-gradient(225deg, var(--fawn), var(--citrine), var(--burnt-orange), var(--russian-violet));
  --gradient-bottom-left: linear-gradient(315deg, var(--fawn), var(--citrine), var(--burnt-orange), var(--russian-violet));
  --gradient-radial: radial-gradient(var(--fawn), var(--citrine), var(--burnt-orange), var(--russian-violet));
}

/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-primary);
  color: var(--text-primary);
  line-height: 1.5;
  background-color: var(--background-light);
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  margin-bottom: var(--spacing-sm);
  font-weight: 600;
  font-family: "Nasalization";
  letter-spacing: 0.2vw;
  line-height: 1.2;
}

h1 { font-size: var(--font-size-3xl); }
h2 { font-size: var(--font-size-2xl); }
h3 { font-size: var(--font-size-xl); }

p {
  margin-bottom: var(--spacing-sm);
}

/* Links */
a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color var(--transition-fast);
}

a:hover {
  color: var(--secondary-color);
}

/* Buttons */
button,
.button {
  display: inline-block;
  padding: var(--spacing-xs) var(--spacing-sm);
  border: none;
  border-radius: var(--border-radius-sm);
  background-color: var(--primary-color);
  color: white;
  font-size: var(--font-size-base);
  cursor: pointer;
  transition: background-color var(--transition-fast);
}

button:hover,
.button:hover {
  background-color: var(--secondary-color);
}

/* Container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 var(--spacing-sm);
}

/* Grid and Flex Utilities */
.grid {
  display: grid;
  gap: var(--spacing-md);
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  :root {
    --font-size-3xl: 1.5rem;
    --font-size-2xl: 1.25rem;
    --font-size-xl: 1.125rem;
    --spacing-xl: 2rem;
  }

  .container {
    padding: 0 var(--spacing-xs);
  }
}
