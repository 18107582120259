/* Contact Page Styles */
.contact-page {
  min-height: 100vh;
  background: var(--background-light);
  overflow-x: hidden;
}

/* Contact Hero Section */
.contact-hero {
  background: var(--gradient-bottom-right);
  color: white;
  text-align: center;
  padding: var(--spacing-xl) var(--spacing-lg);
  position: relative;
  overflow: hidden;
}

.contact-hero::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.15) 0%, transparent 70%);
  animation: rotateGlow 15s linear infinite;
  z-index: 0;
}

.contact-hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.contact-hero h1,
.contact-hero p,
.contact-hero .hero-cta {
  position: relative;
  z-index: 1;
}

.contact-hero h1 {
  font-size: var(--font-size-3xl);
  margin-bottom: var(--spacing-sm);
  animation: slideInLeft var(--transition-normal) ease-out;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
}

.contact-hero p {
  font-size: var(--font-size-lg);
  opacity: 0.9;
  max-width: 800px;
  margin: 0 auto var(--spacing-md);
  animation: fadeInUp var(--transition-normal) ease-out 0.2s forwards;
}

.hero-subtitle {
  font-size: var(--font-size-base);
  animation: fadeIn var(--transition-normal) ease-out 0.4s forwards;
  opacity: 0;
}

.hero-cta {
  background: var(--accent-color);
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  transition: all var(--transition-fast);
  animation: bounceIn var(--transition-normal) ease-out 0.6s forwards;
  opacity: 0;
  position: relative;
  overflow: hidden;
}

.hero-cta::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all var(--transition-normal);
}

.hero-cta:hover::after {
  width: 300%;
  height: 300%;
}

.hero-cta:hover {
  background: var(--primary-color);
  transform: scale(1.05) rotate(2deg);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* Contact Content */
.contact-content {
  padding: var(--spacing-xl) 0;
  background: var(--background-dark);
  position: relative;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
}

/* Contact Info */
.contact-info {
  padding: var(--spacing-lg);
}

.contact-info h2 {
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-2xl);
  animation: fadeInDown var(--transition-normal) ease-out;
}

.info-subtitle {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-base);
  animation: fadeIn var(--transition-normal) ease-out 0.2s forwards;
  opacity: 0;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-lg);
}

.info-item {
  padding: var(--spacing-md);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  transition: all var(--transition-fast);
  position: relative;
  overflow: hidden;
}

.info-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient-top-right);
  opacity: 0;
  transition: opacity var(--transition-fast);
  z-index: -1;
}

.info-item:hover::before {
  opacity: 0.1;
}

.info-item:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.info-icon {
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-sm);
  color: var(--primary-color);
  transition: transform var(--transition-fast);
}

.info-item:hover .info-icon {
  transform: scale(1.2) rotate(5deg);
}

.info-item h3 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
}

.info-item p {
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

.social-links {
  margin-top: var(--spacing-lg);
}

.social-links h3 {
  color: var(--text-primary);
  margin-bottom: var(--spacing-sm);
}

.social-grid {
  display: flex;
  gap: var(--spacing-md);
  flex-wrap: wrap;
}

.social-link {
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--primary-color);
  color: white;
  border-radius: var(--border-radius-sm);
  transition: all var(--transition-fast);
  position: relative;
  overflow: hidden;
}

.social-link::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transition: all var(--transition-normal);
}

.social-link:hover::after {
  left: 100%;
}

.social-link:hover {
  background: var(--secondary-color);
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Contact Form */
.contact-form {
  padding: var(--spacing-lg);
  background: var(--background-light);
  border-radius: var(--border-radius-lg);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-2xl);
  animation: fadeInDown var(--transition-normal) ease-out;
}

.form-subtitle {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-base);
  animation: fadeIn var(--transition-normal) ease-out 0.2s forwards;
  opacity: 0;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-group label {
  color: var(--text-primary);
  margin-bottom: var(--spacing-xs);
  font-size: var(--font-size-base);
  transition: all var(--transition-fast);
}

.form-group input,
.form-group textarea {
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-base);
  background: var(--background-light);
  transition: all var(--transition-fast);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 8px rgba(37, 99, 235, 0.4);
  background: white;
}

.form-group input:focus + label,
.form-group textarea:focus + label {
  color: var(--primary-color);
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  background: var(--primary-color);
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  color: white;
  transition: all var(--transition-fast);
  position: relative;
  overflow: hidden;
}

.submit-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all var(--transition-normal);
}

.submit-button:hover::after {
  width: 300%;
  height: 300%;
}

.submit-button:hover {
  background: var(--secondary-color);
  transform: scale(1.05) rotate(2deg);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Contact Map */
.contact-map {
  padding: var(--spacing-xl) 0;
  background: var(--fawn);
  position: relative;
}

.contact-map::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 184, 111, 0.1) 0%, transparent 70%);
  z-index: 0;
}

.contact-map h2,
.contact-map p,
.contact-map iframe {
  position: relative;
  z-index: 1;
}

.contact-map h2 {
  text-align: center;
  color: var(--russian-violet);
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-2xl);
  animation: fadeInDown var(--transition-normal) ease-out;
}

.map-subtitle {
  text-align: center;
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-base);
  animation: fadeIn var(--transition-normal) ease-out 0.2s forwards;
  opacity: 0;
}

.contact-map iframe {
  width: 100%;
  border-radius: var(--border-radius-lg);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  transition: transform var(--transition-fast);
}

.contact-map iframe:hover {
  transform: scale(1.02);
}

/* Animations */
@keyframes slideInLeft {
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInUp {
  from { transform: translateY(30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeInDown {
  from { transform: translateY(-30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes bounceIn {
  0% { transform: scale(0.8); opacity: 0; }
  60% { transform: scale(1.05); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes rotateGlow {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-hero,
  .contact-content,
  .contact-map {
    padding: var(--spacing-lg) 0;
  }

  .contact-hero h1 { font-size: var(--font-size-2xl); }
  .contact-hero p { font-size: var(--font-size-base); }
  .contact-info h2,
  .contact-form h2,
  .contact-map h2 { font-size: var(--font-size-xl); }

  .contact-grid {
    grid-template-columns: 1fr;
  }

  .info-grid {
    grid-template-columns: 1fr;
  }

  .social-grid {
    flex-direction: column;
    align-items: center;
  }

  .contact-form {
    padding: var(--spacing-md);
  }

  .contact-map iframe {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .contact-hero h1 { font-size: var(--font-size-xl); }
  .contact-info h2,
  .contact-form h2,
  .contact-map h2 { font-size: var(--font-size-lg); }
}