/* About Page Styles */
.about-page {
  min-height: 100vh;
  background: var(--background-light);
}

/* About Hero Section */
.about-hero {
  background: var(--gradient-bottom-right);
  color: white;
  text-align: center;
  padding: var(--spacing-xl) var(--spacing-lg);
  position: relative;
  overflow: hidden;
}

.about-hero::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.about-hero h1,
.about-hero p,
.about-hero .hero-cta {
  position: relative;
  z-index: 1;
}

.about-hero h1 {
  font-size: var(--font-size-3xl);
  margin-bottom: var(--spacing-sm);
  animation: slideInLeft var(--transition-normal) ease-out;
}

.about-hero p {
  font-size: var(--font-size-lg);
  opacity: 0.9;
  max-width: 800px;
  margin: 0 auto var(--spacing-md);
  animation: fadeInUp var(--transition-normal) ease-out 0.2s forwards;
}

.hero-subtitle {
  font-size: var(--font-size-base);
  animation: fadeIn var(--transition-normal) ease-out 0.4s forwards;
  opacity: 0;
}

.hero-cta {
  background: var(--accent-color);
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius-md);
  transition: all var(--transition-fast);
  animation: bounceIn var(--transition-normal) ease-out 0.6s forwards;
  opacity: 0;
}

.hero-cta:hover {
  background: var(--primary-color);
  transform: scale(1.05);
}

/* Company Story */
.company-story {
  padding: var(--spacing-xl) 0;
  background: var(--background-dark);
}

.story-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  align-items: center;
}

.story-text {
  padding: var(--spacing-lg);
  background: white;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal);
}

.story-text:hover {
  transform: translateY(-5px);
}

.story-text h2 {
  font-size: var(--font-size-2xl);
  color: var(--russian-violet);
  margin-bottom: var(--spacing-md);
  position: relative;
  font-weight: 700;
  letter-spacing: -0.5px;
  animation: fadeInDown var(--transition-normal) ease-out;
}

.story-text h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 3px;
  background: var(--accent-color);
  transition: width var(--transition-normal);
}

.story-text:hover h2::after {
  width: 100px;
}

.story-text p {
  color: var(--text-primary);
  line-height: 1.8;
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-base);
  transition: opacity var(--transition-normal);
  animation: fadeIn var(--transition-normal) ease-out 0.2s forwards;
}

.story-text p:hover {
  color: var(--russian-violet);
}

.story-text p:last-child {
  margin-bottom: 0;
}

.story-image {
  position: relative;
  height: 300px;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
}

.image-placeholder {
  width: 100%;
  height: 100%;
  background: var(--gradient-radial);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-lg);
  transition: transform var(--transition-normal);
  animation: pulse 1.5s ease-in-out infinite;
}

.image-placeholder:hover {
  transform: scale(1.02);
}

/* Milestones Section */
.milestones {
  padding: var(--spacing-xl) 0;
  background: var(--background-light);
}

.milestones h2 {
  text-align: center;
  font-size: var(--font-size-2xl);
  color: var(--text-dark);
  margin-bottom: var(--spacing-xl);
  position: relative;
}

.milestones h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--accent-color);
  transition: width var(--transition-normal);
}

.milestone-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacing-lg);
  padding: 0 var(--spacing-md);
}

.milestone-card {
  background: white;
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  text-align: center;
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  position: relative;
  overflow: hidden;
}

.milestone-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.milestone-card h3 {
  font-size: var(--font-size-xl);
  color: var(--accent-color);
  margin-bottom: var(--spacing-sm);
  font-weight: 700;
}

.milestone-card p {
  color: var(--text-secondary);
  line-height: 1.6;
  font-size: var(--font-size-base);
}

.milestone-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: var(--accent-color);
  opacity: 0.5;
}

/* Mission Vision */
.mission-vision {
  padding: var(--spacing-xl) 0;
  background: var(--background-light);
}

.mv-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-lg);
}

.mission, .vision {
  padding: var(--spacing-lg);
  background: white;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal);
}

.mission:hover, .vision:hover {
  transform: translateY(-5px);
}

.mission h2, .vision h2 {
  font-size: var(--font-size-2xl);
  color: var(--text-dark);
  margin-bottom: var(--spacing-md);
  position: relative;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.mission h2::after, .vision h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 3px;
  background: var(--accent-color);
  transition: width var(--transition-normal);
}

.mission:hover h2::after, .vision:hover h2::after {
  width: 100px;
}

.mission p, .vision p {
  color: var(--text-secondary);
  line-height: 1.8;
  font-size: var(--font-size-base);
  opacity: 0.9;
  transition: opacity var(--transition-normal);
}

.mission p:hover, .vision p:hover {
  opacity: 1;
}

.mv-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-lg);
}

.mission, .vision {
  padding: var(--spacing-lg);
  background: var(--background-dark);
  border-radius: var(--border-radius-md);
  transition: all var(--transition-fast);
}

.mission:hover, .vision:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.mission h2 {
  color: var(--burnt-orange);
}

.vision h2 {
  color: var(--citrine);
}

/* Milestones Section */
.milestones {
  padding: var(--spacing-xl) 0;
  background: var(--fawn);
}

.milestones h2 {
  text-align: center;
  margin-bottom: var(--spacing-xl);
  color: var(--russian-violet);
}

.milestone-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-lg);
}

.milestone-card {
  padding: var(--spacing-md);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  text-align: center;
  transition: all var(--transition-fast);
}

.milestone-card:hover {
  transform: scale(1.05);
  background: var(--gradient-top-right);
  color: white;
}

.milestone-card h3 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
}

.milestone-card:hover h3 {
  color: white;
}

/* Team Section */
.team {
  padding: var(--spacing-xl) 0;
  background: var(--background-light);
}

.team h2 {
  text-align: center;
  font-size: var(--font-size-2xl);
  color: var(--text-dark);
  margin-bottom: var(--spacing-xl);
  position: relative;
}

.team h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--accent-color);
  transition: width var(--transition-normal);
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: var(--spacing-lg);
  padding: 0 var(--spacing-md);
}

.team-member {
  background: white;
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.team-member:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.member-image {
  height: 280px;
  background: var(--gradient-top-right);
  position: relative;
}

.member-info {
  padding: var(--spacing-lg);
  text-align: center;
}

.member-info h3 {
  font-size: var(--font-size-xl);
  color: var(--text-dark);
  margin-bottom: var(--spacing-xs);
}

.member-info .position {
  color: var(--accent-color);
  font-weight: 600;
  margin-bottom: var(--spacing-sm);
}

.member-info p {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-bottom: var(--spacing-xs);
  font-size: var(--font-size-sm);
}

.member-info .bio {
  font-style: italic;
  opacity: 0.8;
}
.team {
  padding: var(--spacing-xl) 0;
  background: var(--background-dark);
}

.team h2 {
  text-align: center;
  margin-bottom: var(--spacing-xl);
  color: var(--text-primary);
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: var(--spacing-lg);
}

.team-member {
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all var(--transition-normal);
}

.team-member:hover {
  transform: translateY(-10px) scale(1.02);
}

.member-image {
  height: 200px;
  background: var(--gradient-top);
  transition: all var(--transition-fast);
}

.team-member:hover .member-image {
  background: var(--gradient-bottom);
}

.member-info {
  padding: var(--spacing-md);
  text-align: center;
}

.member-info h3 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-xs);
}

.position {
  color: var(--burnt-orange);
  font-style: italic;
}

.bio {
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
}

/* Values Section */
.values {
  padding: var(--spacing-xl) 0;
  background: var(--russian-violet);
  color: var(--accent-color);
}

.values h2 {
  text-align: center;
  margin-bottom: var(--spacing-xl);
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-lg);
}

.value-card {
  padding: var(--spacing-lg);
  text-align: center;
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all var(--transition-fast);
}

.value-card:hover {
  background: var(--gradient-top-right);
  color: white;
  transform: scale(1.05);
}

.value-icon {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-sm);
}

.value-card h3 {
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
}

.value-card:hover h3 {
  color: white;
}

/* Animations */
@keyframes slideInLeft {
  from { transform: translateX(-50px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInUp {
  from { transform: translateY(30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeInDown {
  from { transform: translateY(-30px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes bounceIn {
  0% { transform: scale(0.8); opacity: 0; }
  60% { transform: scale(1.05); opacity: 1; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes pulse {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .story-grid, .mv-grid, .milestone-grid {
    grid-template-columns: 1fr;
  }

  .story-image {
    height: 200px;
    margin-top: var(--spacing-md);
  }

  .team-grid {
    grid-template-columns: 1fr;
  }

  .about-hero,
  .company-story,
  .mission-vision,
  .milestones,
  .team,
  .values {
    padding: var(--spacing-lg) 0;
  }

  .about-hero h1 { font-size: var(--font-size-2xl); }
  .about-hero p { font-size: var(--font-size-base); }
}