/* Home Page Styles */
.home-page {
  min-height: 100vh;
  overflow-x: hidden;
  background: var(--background-light); /* Using global background */
}

/* Hero Section */
.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-bottom-right); /* Using global gradient */
  padding: var(--spacing-xl);
  overflow: hidden;
}

.hero-content {
  text-align: center;
  color: var(--background-light); /* Using global light color instead of ivory */
  position: relative;
  z-index: 2;
}

.hero h1 {
  font-size: var(--font-size-3xl); /* Using global typography */
  margin-bottom: var(--spacing-md);
  animation: slideInLeft 0.8s ease-out forwards; /* Using fixed value from global transition-slow */
}

.hero p {
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-md);
  animation: fadeInUp 0.8s ease-out 0.3s forwards;
  opacity: 0;
}

.hero-subtitle {
  font-size: var(--font-size-lg);
  opacity: 0;
  animation: fadeInUp 0.8s ease-out 0.6s forwards;
}

.hero-button {
  padding: var(--spacing-sm) var(--spacing-lg);
  background: var(--accent-color); /* Using global accent */
  border: none;
  border-radius: var(--border-radius-md);
  color: white;
  font-size: var(--font-size-lg);
  cursor: pointer;
  transition: all var(--transition-fast);
  animation: bounceIn var(--transition-normal) ease-out 0.9s forwards;
  opacity: 0;
}

.hero-button:hover {
  transform: scale(1.1) rotate(2deg);
  background: var(--primary-color); /* Using global primary */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
  animation: overlayFade 2s ease-in forwards;
}

/* Features Section */
.features {
  padding: var(--spacing-xl);
  background: var(--background-light); /* Using global background */
  position: relative;
}

.features h2 {
  text-align: center;
  color: var(--text-primary); /* Using global text color */
  margin-bottom: var(--spacing-xl);
  font-size: var(--font-size-2xl);
  animation: fadeInDown 0.8s ease-out forwards;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  max-width: 1200px; /* Matches global container max-width */
  margin: 0 auto;
}

.feature-card {
  padding: var(--spacing-lg);
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  transition: all var(--transition-normal) ease-out;
  position: relative;
  opacity: 0;
  transform: translateY(50px);
  animation: cardRise 0.8s ease-out forwards;
}

.feature-card:nth-child(1) { animation-delay: 0.2s; }
.feature-card:nth-child(2) { animation-delay: 0.4s; }
.feature-card:nth-child(3) { animation-delay: 0.6s; }
.feature-card:nth-child(4) { animation-delay: 0.8s; }

.feature-card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-md);
  transition: transform var(--transition-fast);
}

.feature-card:hover .feature-icon {
  transform: scale(1.2) rotate(5deg);
}

.feature-card h3 {
  color: var(--text-primary); /* Using global text color */
  transition: color var(--transition-fast);
}

.feature-card:hover h3 {
  color: var(--primary-color); /* Using global primary */
}

.feature-detail {
  color: var(--text-secondary); /* Using global secondary text */
  font-size: var(--font-size-sm);
  opacity: 0;
  animation: fadeIn 0.8s ease-out 0.3s forwards;
}

/* Stats Section */
.stats {
  padding: var(--spacing-xl);
  background: var(--background-dark); /* Using global dark background */
  position: relative;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-lg);
  max-width: 1200px; /* Matches global container max-width */
  margin: 0 auto;
}

.stat-item {
  text-align: center;
  color: var(--text-primary);
  opacity: 0;
  transform: scale(0.8);
  animation: scaleIn 0.8s ease-out forwards;
}

.stat-item:nth-child(1) { animation-delay: 0.2s; }
.stat-item:nth-child(2) { animation-delay: 0.4s; }
.stat-item:nth-child(3) { animation-delay: 0.6s; }

.stat-item h3 {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-sm);
  color: var(--accent-color); /* Using global accent */
}

/* CTA Section */
.cta {
  padding: var(--spacing-xl);
  background: var(--russian-violet); /* Using additional global color */
  color: var(--background-light);
  text-align: center;
  position: relative;
}

.cta-content {
  animation: fadeIn 0.8s ease-out forwards;
}

.cta h2 {
  font-size: var(--font-size-2xl);
  margin-bottom: var(--spacing-md);
}

.cta-subtitle {
  font-size: var(--font-size-lg);
  opacity: 0.8;
  margin-bottom: var(--spacing-lg);
}

.cta-button {
  padding: var(--spacing-sm) var(--spacing-lg);
  background: var(--accent-color);
  border: none;
  border-radius: var(--border-radius-md);
  color: white;
  font-size: var(--font-size-lg);
  cursor: pointer;
  transition: all var(--transition-fast);
  position: relative;
  overflow: hidden;
}

.cta-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all var(--transition-normal);
}

.cta-button:hover::after {
  width: 200%;
  height: 200%;
}

.cta-button:hover {
  background: var(--primary-color);
  transform: scale(1.05) rotate(3deg);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Animations */
@keyframes slideInLeft {
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes fadeInUp {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeInDown {
  from { transform: translateY(-50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes overlayFade {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes cardRise {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes bounceIn {
  0% { transform: scale(0.3); opacity: 0; }
  50% { transform: scale(1.05); opacity: 1; }
  70% { transform: scale(0.9); }
  100% { transform: scale(1); opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero { min-height: 80vh; padding: var(--spacing-lg); }
  .hero h1 { font-size: var(--font-size-2xl); }
  .hero p { font-size: var(--font-size-lg); }
  .hero-subtitle { font-size: var(--font-size-base); }
  .hero-button { padding: var(--spacing-xs) var(--spacing-md); }
  
  .features, .stats, .cta { padding: var(--spacing-lg); }
  .features h2, .cta h2 { font-size: var(--font-size-xl); }
  .feature-card { padding: var(--spacing-md); }
  .stat-item h3 { font-size: var(--font-size-xl); }
  
  .feature-grid, .stats-grid { 
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
  }
}

@media (max-width: 480px) {
  .hero h1 { font-size: var(--font-size-xl); }
  .hero p { font-size: var(--font-size-base); }
  .features h2, .cta h2 { font-size: var(--font-size-lg); }
}