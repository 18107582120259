/* Navbar Styles */
.navbar {
  background: var(--background-dark);
  padding: var(--spacing-md) var(--spacing-lg);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Navbar Brand */
.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-family: "Nasalization";
  letter-spacing: 0.5vw;
  font-size: var(--font-size-xl);
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 700;
  transition: all var(--transition-fast);
}

.logo:hover {
  color: var(--accent-color);
  text-shadow: 0 0 5px rgba(59, 130, 246, 0.3);
}

/* Hamburger Menu */
.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: var(--spacing-sm);
  position: relative;
  z-index: 1001;
}

.hamburger span {
  display: block;
  width: 28px;
  height: 3px;
  background: var(--text-primary);
  margin: 6px 0;
  border-radius: 2px;
  transition: all var(--transition-fast);
}

.close-icon {
  font-size: var(--font-size-lg);
  color: var(--text-primary);
  transition: all var(--transition-fast);
}

.hamburger:hover .close-icon {
  color: var(--accent-color);
}

/* Navbar Menu */
.navbar-menu {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.menu-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
}

.nav-link {
  color: var(--text-primary);
  text-decoration: none;
  font-size: var(--font-size-base);
  padding: var(--spacing-sm) var(--spacing-md);
  transition: all var(--transition-fast);
  position: relative;
  font-weight: 500;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--accent-color);
  transition: width var(--transition-fast);
}

.nav-link:hover::after,
.nav-link:focus::after {
  width: 100%;
}

.nav-link:hover,
.nav-link:focus {
  color: var(--accent-color);
}

/* Products Menu (Desktop Dropdown) */
.products-menu {
  position: relative;
}

.products-toggle {
  background: none;
  border: none;
  color: var(--text-primary);
  font-size: var(--font-size-base);
  padding: var(--spacing-sm) var(--spacing-md);
  cursor: pointer;
  transition: all var(--transition-fast);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.products-toggle:hover,
.products-toggle:focus {
  color: var(--accent-color);
}

.dropdown-arrow {
  margin-left: var(--spacing-xs);
  font-size: var(--font-size-sm);
  transition: transform var(--transition-fast);
}

.products-menu:hover .dropdown-arrow,
.products-submenu.active .dropdown-arrow {
  transform: rotate(180deg);
}

.products-submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: var(--background-light);
  border-radius: var(--border-radius-md);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  min-width: 250px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
}

.products-menu:hover .products-submenu,
.products-submenu.active {
  display: block;
  animation: fadeInDown var(--transition-fast) ease-out;
}

.submenu-section {
  padding: var(--spacing-sm) 0;
}

.submenu-title {
  display: block;
  color: var(--text-secondary);
  font-size: var(--font-size-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: var(--spacing-sm);
}

.submenu-link {
  display: block;
  color: var(--text-primary);
  text-decoration: none;
  padding: var(--spacing-sm) var(--spacing-md);
  transition: all var(--transition-fast);
  font-size: var(--font-size-sm);
}

.submenu-link:hover,
.submenu-link:focus {
  background: var(--primary-color);
  color: white;
  border-radius: var(--border-radius-sm);
  transform: translateX(5px);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .hamburger {
    display: block;
    background-color: var(--secondary-color);
  }
  .hamburger:hover {
    background-color: var(--accent-color); 
  }

  .navbar-menu {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100vh;
    background: var(--background-dark);
    display: flex;
    flex-direction: column;
    padding: var(--spacing-xl) var(--spacing-lg);
    z-index: 1000;
    transition: left var(--transition-normal);
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
  }

  .navbar-menu.active {
    left: 0;
  }

  .menu-wrapper {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }

  .nav-link,
  .products-toggle {
    width: 100%;
    text-align: left;
    padding: var(--spacing-md);
    font-size: var(--font-size-lg);
    color: var(--text-primary);
    border-bottom: 1px solid var(--border-color);
  }

  .products-menu {
    width: 100%;
  }

  .products-submenu {
    position: static;
    width: 100%;
    background: var(--background-dark);
    box-shadow: none;
    padding: var(--spacing-sm) var(--spacing-md);
    max-height: 300px;
    overflow-y: auto;
    display: none;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
  }

  .products-submenu.active {
    display: block;
  }

  .submenu-section {
    padding: 0;
  }

  .submenu-title {
    color: var(--accent-color);
    font-size: var(--font-size-base);
    padding: var(--spacing-sm);
    border-bottom: none;
  }

  .submenu-link {
    padding: var(--spacing-sm);
    font-size: var(--font-size-base);
    color: var(--text-primary);
    opacity: 0.8;
  }

  .submenu-link:hover,
  .submenu-link:focus {
    background: none;
    color: var(--accent-color);
    transform: none;
    opacity: 1;
  }
}

/* Animations */
@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
  from { left: -300px; }
  to { left: 0; }
}