/* Footer Styles */
.footer {
  display: grid;
  flex-wrap: wrap;
  background: var(--background-dark);
  color: var(--text-primary);
  padding: var(--spacing-xl) var(--spacing-lg);
  position: relative;
  border-top: 1px solid var(--border-color);
  background: rgba(31, 41, 55, 0.95);
  grid-template-columns: repeat(4, 1fr);
}

/* Footer Section */
.footer-section {
  padding: var(--spacing-md);
}

.footer-section h3 {
  color: var(--primary-color);
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-md);
  position: relative;
  animation: fadeInDown var(--transition-normal) ease-out;
}

.footer-section h3::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 30px;
  height: 2px;
  background: var(--accent-color);
  transition: width var(--transition-fast);
}

.footer-section:hover h3::after {
  width: 50px;
}

.footer-section p {
  font-size: var(--font-size-sm);
  color: var(--border-color);
  line-height: 1.6;
}

/* About Section */
.about .footer-logo {
  margin-top: var(--spacing-md);
}

.about .footer-logo span {
  font-size: var(--font-size-xl);
  font-weight: 700;
  color: var(--primary-color);
  transition: all var(--transition-fast);
}

.about .footer-logo span:hover {
  color: var(--accent-color);
  text-shadow: 0 0 10px rgba(59, 130, 246, 0.5);
}

/* Quick Links */
.quick-links ul {
  list-style: none;
  padding: 0;
}

.quick-links li {
  margin-bottom: var(--spacing-sm);
}

.quick-links a {
  color: var(--background-dark);
  text-decoration: none;
  font-size: var(--font-size-sm);
  transition: all var(--transition-fast);
}

.quick-links a:hover,
.quick-links a:focus {
  color: var(--accent-color);
  transform: translateX(5px);
  display: inline-block;
}

/* Contact Section */
.contact p {
  margin-bottom: var(--spacing-sm);
}

.contact a {
  color: var(--background-dark);
  text-decoration: none;
  transition: all var(--transition-fast);
}

.contact a:hover,
.contact a:focus {
  color: var(--accent-color);
}

/* Follow Us Section */
.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-md);
}

.social-links a {
  color: var(--background-dark);
  text-decoration: none;
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-sm);
  background: rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius-sm);
  transition: all var(--transition-fast);
}

.social-icon {
  margin-right: var(--spacing-xs);
}

.social-links a:hover,
.social-links a:focus {
  background: var(--primary-color);
  color: white;
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Newsletter Section */
.newsletter-form {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
}

.newsletter-form input {
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  background: var(--background-light);
  color: var(--text-primary);
  transition: all var(--transition-fast);
  flex: 1;
}

.newsletter-form input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 5px rgba(37, 99, 235, 0.3);
}

.newsletter-form .subscribe-button {
  background: var(--primary-color);
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--border-radius-sm);
  color: white;
  transition: all var(--transition-fast);
}

.newsletter-form .subscribe-button:hover,
.newsletter-form .subscribe-button:focus {
  background: var(--secondary-color);
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Footer Bottom */
.footer-bottom {
  text-align: center;
  padding: var(--spacing-md) 0;
  border-top: 1px solid var(--border-color);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: var(--spacing-md);
  }

  .footer-section {
    padding: var(--spacing-sm);
  }

  .newsletter-form {
    flex-direction: column;
  }

  .newsletter-form input,
  .newsletter-form .subscribe-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .footer-section h3 {
    font-size: var(--font-size-base);
  }

  .social-links {
    flex-direction: column;
    align-items: center;
  }
}
